# re usable toggle component
window.toggleContent = (toggleId) ->
  content = document.getElementById "#{toggleId}-content"

  # security check if is func (throw error otherwise)
  isFunction = (variable) -> typeof variable is 'function'
  if isFunction(event?.target?.closest) && event.target.closest('[data-dropdown-ignore]')
    return;

  if content.classList.contains 'open'
    closeContent toggleId
  else
    openContent toggleId

window.openContent = (toggleId) ->
  content = document.getElementById "#{toggleId}-content"
  icon = document.getElementById "toggleIcon#{toggleId}"

  # open content
  if not content.classList.contains 'open'
    content.classList.add 'open'

  # chevron up
  if icon?.classList
    icon.classList.remove 'fa-chevron-down'
    icon.classList.add 'fa-chevron-up'

window.closeContent = (toggleId) ->
  content = document.getElementById "#{toggleId}-content"
  icon = document.getElementById "toggleIcon#{toggleId}"

  # close content
  if content.classList.contains 'open'
    content.classList.remove 'open'

  # chevron up
  if icon?.classList
    icon.classList.remove 'fa-chevron-up'
    icon.classList.add 'fa-chevron-down'

window.showToggleContentOnError = (toggleId) ->
  content = document.getElementById "#{toggleId}-content"

  # get nodes with the error classname in the content
  errors = content.querySelectorAll '.error', ':not(error hidden)'
  # check if at least one of them is displayed
  isError = Array.from(errors).some (err) -> 
    disp = err.style.display
    return err and disp isnt 'hidden' and disp isnt 'none' and !err.classList.contains('hidden')

  # if errors open content
  if content and isError
    openContent toggleId
