# Helper function to dynamically add a flash message
window.displayFlashMessage = (message, type) ->
  flashesDiv = document.querySelector('.flashes')
  if not ['primary', 'success', 'alert', 'notice', 'warning'].includes type
    type = 'primary'

  if flashesDiv
    flashHTML = """
      <div class="flash callout #{type}" data-closable>
        #{message}
        <div class="close-button-container">
          <button class="close-button" aria-label="Dismiss alert" type="button" data-close>
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
    """
    flashesDiv.innerHTML += flashHTML
