document.addEventListener("DOMContentLoaded", () => {
  console.log("DOM content loaded");

  // Use a more specific selector if needed
  const registerClickButtons = document.querySelectorAll(".search-repair-box");

  registerClickButtons.forEach((button) => {
    button.addEventListener("click", () => {
      // Correctly access data attribute
      const technicianId = button.dataset.technicianId;

      if (technicianId) {
        fetch(`/technician_clicks/${technicianId}/register_click`, {
          method: "POST",
          headers: {
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ type: 'click' })
        })
          .then((response) => {
            if (!response.ok) {
              if (response.status === 429) {
                console.log('Click already registered within the last 5 minutes')
              } else {
                throw new Error('An error occurred.');
              }
            }
            return response.json();
          })
          .then((data) => {
          })
          .catch((error) => {
            alert(error.message);
          });
      } else {
        console.log("Technican ID not found")
      }
    });
  });
});
